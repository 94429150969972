import { ApiClient } from "@/common/http-common"
function checkUserSessionPublic(routeTo, routeFrom, next) {
    ApiClient().get("/v1/admin/user").then(function (response) {
        if (response.data.status == true) {
            localStorage.setItem("user", JSON.stringify(response.data.item))
            localStorage.setItem("platforms", JSON.stringify(response.data.item.employee.details))
            next({ name: 'home' })
        } else {
            next()
        }
    }).catch(
        function () {
            next()
        }
    )
}
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                checkUserSessionPublic(routeTo, routeFrom, next)
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                checkUserSessionPublic(routeTo, routeFrom, next)
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                checkUserSessionPublic(routeTo, routeFrom, next)
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                ApiClient().delete("/v1/admin/user/logout").then(function () {
                    localStorage.clear()
                    next({ name: 'home' })
                }).catch(
                    function () {
                        localStorage.clear()
                        next({ name: 'home' })
                    }
                )
            },
        },
    },
    {
        name: 'dashboard',
        path: '/',
        redirect: '/home',
        component: () => import('@/views/layouts/main'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                ApiClient().get("/v1/admin/user").then(function (response) {
                    if (response.data.status == true) {
                        localStorage.setItem("user", JSON.stringify(response.data.item))
                        localStorage.setItem("platforms", JSON.stringify(response.data.item.employee.details))
                        if (response){
                            if (response.data){
                                if (response.data.item){
                                    if (response.data.item.employee){
                                        if (response.data.item.employee.details){
                                            if (response.data.item.employee.details.length == 0){
                                                localStorage.clear()
                                                next({ name: 'login' })
                                            }else{
                                                if (localStorage.getItem("platformId")){
                                                    next()
                                                }else{
                                                    localStorage.setItem("platformId", response.data.item.employee.details[0].domain.id)
                                                    next()
                                                }
                                                
                                            }
                                        }else{
                                            localStorage.clear()
                                            next({ name: 'login' })
                                        }
                                    }else{
                                        localStorage.clear()
                                        next({ name: 'login' })
                                    }
                                }else{
                                    localStorage.clear()
                                    next({ name: 'login' })
                                }
                            }else{
                                localStorage.clear()
                                next({ name: 'login' })
                            }
                        }else{
                            localStorage.clear()
                             next({ name: 'login' })
                        }
                       
                    } else {
                        localStorage.clear()
                        next({ name: 'login' })
                    }
                }).catch(
                    function () {
                        localStorage.clear()
                        next({ name: 'login' })
                    }
                )
            },
        },
        children: [
            {
                name: 'home',
                path: 'home',
                component: () => import('../views/pages/dashboard/index'),
            }, {
                path: '/platform',
                name: 'platform',
                component: () => import('../views/pages/platform/index'),
            },{
                path: '/platform/detail',
                name: 'platform/detail',
                component: () => import('../views/pages/platform/index-detail'),
            }, {
                path: '/platform/detail/update',
                name: 'platform/detail/update',
                component: () => import('../views/pages/platform/update'),
            }, {
                path: '/platform/create',
                name: 'platform/create',
                component: () => import('../views/pages/platform/create'),
            },{
                path: '/platform/detail/tax',
                name: 'platform/detail/tax',
                component: () => import('../views/pages/tax/index'),
            },{
                path: '/platform/detail/tax/create',
                name: 'platform/detail/tax/create',
                component: () => import('../views/pages/tax/create'),
            },{
                path: '/platform/detail/tax/update',
                name: 'platform/detail/tax/update',
                component: () => import('../views/pages/tax/update'),
            },{
                path: '/platform/detail/tags',
                name: 'platform/detail/tags',
                component: () => import('../views/pages/tags/index'),
            },{
                path: '/platform/detail/tags/create',
                name: 'platform/detail/tags/create',
                component: () => import('../views/pages/tags/create'),
            },{
                path: '/platform/detail/tags/update',
                name: 'platform/detail/tags/update',
                component: () => import('../views/pages/tags/update'),
            },{
                path: '/platform/detail/brands',
                name: 'platform/detail/brands',
                component: () => import('../views/pages/brands/index'),
            },{
                path: '/platform/detail/brands/create',
                name: 'platform/detail/brands/create',
                component: () => import('../views/pages/brands/create'),
            },{
                path: '/platform/detail/brands/update',
                name: 'platform/detail/brands/update',
                component: () => import('../views/pages/brands/update'),
            },{
                path: '/platform/detail/category',
                name: 'platform/detail/category',
                component: () => import('../views/pages/category/index'),
            },{
                path: '/platform/detail/category/create',
                name: 'platform/detail/category/create',
                component: () => import('../views/pages/category/create'),
            },{
                path: '/platform/detail/category/update',
                name: 'platform/detail/category/update',
                component: () => import('../views/pages/category/update'),
            },{
                path: '/platform/detail/permission',
                name: 'platform/detail/permission',
                component: () => import('../views/pages/permission/index')
            },{
                path: '/platform/detail/permission/create',
                name: 'platform/detail/permission/create',
                component: () => import('../views/pages/permission/create')
            },{
                path: '/platform/detail/permission/update',
                name: 'platform/detail/permission/update',
                component: () => import('../views/pages/permission/update')
            },{
                path: '/platform/detail/currency',
                name: 'platform/detail/currency',
                component: () => import('../views/pages/currency/index')
            },{
                path: '/currency',
                name: 'currency',
                component: () => import('../views/pages/currency/index')
            },{
                path: '/platform/detail/languages',
                name: 'platform/detail/languages',
                component: () => import('../views/pages/languages/index')
            },{
                path: '/platform/detail/country',
                name: 'platform/detail/country',
                component: () => import('../views/pages/country/index')
            },{
                path: '/platform/detail/country/create',
                name: 'platform/detail/country/create',
                component: () => import('../views/pages/country/create')
            },{
                path: '/platform/detail/country/update',
                name: 'platform/detail/country/update',
                component: () => import('../views/pages/country/update')
            },{
                path: '/country',
                name: 'country',
                component: () => import('../views/pages/country/index')
            },{
                path: '/country/create',
                name: 'country/create',
                component: () => import('../views/pages/country/create')
            },{
                path: '/country/update',
                name: 'country/update',
                component: () => import('../views/pages/country/update')
            },{
                path: '/platform/detail/holiday',
                name: 'platform/detail/holiday',
                component: () => import('../views/pages/holiday/index')
            }, {
                path: '/platform/detail/holiday/create',
                name: 'platform/detail/holiday/create',
                component: () => import('../views/pages/holiday/create')
            },{
                path: '/platform/detail/holiday/update',
                name: 'platform/detail/holiday/update',
                component: () => import('../views/pages/holiday/update')
            },{
                path: '/platform/detail/agent',
                name: 'platform/detail/agent',
                component: () => import('../views/pages/agent/index')
            }, {
                path: '/platform/detail/agent/create',
                name: 'platform/detail/agent/create',
                component: () => import('../views/pages/agent/create'),
            }, {
                path: '/platform/detail/agent/update',
                name: 'platform/detail/agent/update',
                component: () => import('../views/pages/agent/update'),
            },{
                path: '/platform/detail/client',
                name: 'platform/detail/client',
                component: () => import('../views/pages/client/index')
            },{
                path: '/platform/detail/client/create',
                name: 'platform/detail/client/create',
                component: () => import('../views/pages/client/create')
            },{
                path: '/platform/detail/client/update',
                name: 'platform/detail/client/update',
                component: () => import('../views/pages/client/update')
            },{
                path: '/platform/detail/invoice',
                name: 'platform/detail/invoice',
                component: () => import('../views/pages/invoice/index')
            },{
                path: '/platform/detail/invoice/create',
                name: 'platform/detail/invoice/create',
                component: () => import('../views/pages/invoice/create')
            },{
                path: '/platform/detail/invoice/update',
                name: 'platform/detail/invoice/update',
                component: () => import('../views/pages/invoice/update')
            },{
                path: '/platform/detail/products',
                name: 'platform/detail/products',
                component: () => import('../views/pages/products/index')
            },{
                path: '/products',
                name: 'products',
                component: () => import('../views/pages/products/index')
            },{
                path: '/platform/detail/products/create',
                name: 'platform/detail/products/create',
                component: () => import('../views/pages/products/create')
            },{
                path: '/products/create',
                name: 'products/create',
                component: () => import('../views/pages/products/create')
            },{
                path: '/platform/detail/products/update',
                name: 'platform/detail/products/update',
                component: () => import('../views/pages/products/update')
            },{
                path: '/products/update',
                name: 'products/update',
                component: () => import('../views/pages/products/update')
            },{
                path: '/platform/detail/invoice/status',
                name: 'platform/detail/invoice/status',
                component: () => import('../views/pages/invoice-status/index')
            },{
                path: '/platform/detail/invoice/status/create',
                name: 'platform/detail/invoice/status/create',
                component: () => import('../views/pages/invoice-status/create')
            },{
                path: '/platform/detail/invoice/status/update',
                name: 'platform/detail/invoice/status/update',
                component: () => import('../views/pages/invoice-status/update')
            },{
                path: '/platform/detail/invoice/type',
                name: 'platform/detail/invoice/type',
                component: () => import('../views/pages/invoice-type/index')
            },{
                path: '/platform/detail/invoice/type/create',
                name: 'platform/detail/invoice/type/create',
                component: () => import('../views/pages/invoice-type/create')
            },{
                path: '/platform/detail/invoice/type/update',
                name: 'platform/detail/invoice/type/update',
                component: () => import('../views/pages/invoice-type/update')
            },{
                path: '/platform/detail/product/type',
                name: 'platform/detail/product/type',
                component: () => import('../views/pages/product-type/index')
            },{
                path: '/platform/detail/product/type/create',
                name: 'platform/detail/product/type/create',
                component: () => import('../views/pages/product-type/create')
            },{
                path: '/platform/detail/product/type/update',
                name: 'platform/detail/product/type/update',
                component: () => import('../views/pages/product-type/update')
            },{
                path: '/platform/detail/product/price/type',
                name: 'platform/detail/product/price/type',
                component: () => import('../views/pages/product-price-type/index')
            },{
                path: '/platform/detail/product/price/type/create',
                name: 'platform/detail/product/price/type/create',
                component: () => import('../views/pages/product-price-type/create')
            },{
                path: '/platform/detail/product/price/type/update',
                name: 'platform/detail/product/price/type/update',
                component: () => import('../views/pages/product-price-type/update')
            },{
                path: '/platform/detail/module',
                name: 'platform/detail/module',
                component: () => import('../views/pages/module/index')
            },{
                path: '/platform/detail/delivery',
                name: 'platform/detail/delivery',
                component: () => import('../views/pages/delivery/index')
            }, {
                path: '/agent',
                name: 'agent',
                component: () => import('../views/pages/agent/index'),

            }, {
                path: '/agent/create',
                name: 'agent/create',
                component: () => import('../views/pages/agent/create'),

            }, {
                path: '/agent/update',
                name: 'agent/update',
                component: () => import('../views/pages/agent/update'),

            }, {
                path: '/client',
                name: 'client',
                component: () => import('../views/pages/client/index')
            },{
                path: '/client/create',
                name: 'client/create',
                component: () => import('../views/pages/client/create')
            },{
                path: '/client/update',
                name: 'client/update',
                component: () => import('../views/pages/client/update')
            }, {
                path: '/applications',
                name: 'applications',
                component: () => import('../views/pages/applications/index')
            },{
                path: '/applications/update',
                name: 'applications/update',
                component: () => import('../views/pages/applications/update')
            },{
                path: '/applications/update/domain',
                name: 'applications/update/domain',
                component: () => import('../views/pages/applications/update-domain')
            },{
                path: '/tax',
                name: 'tax',
                component: () => import('../views/pages/tax/index'),
            },{
                path: '/tax/create',
                name: 'tax/create',
                component: () => import('../views/pages/tax/create'),
            },{
                path: '/tax/update',
                name: 'tax/update',
                component: () => import('../views/pages/tax/update'),
            },{
                path: '/tags',
                name: 'tags',
                component: () => import('../views/pages/tags/index'),
            },{
                path: '/tags/create',
                name: 'tags/create',
                component: () => import('../views/pages/tags/create'),
            },{
                path: '/tags/update',
                name: 'tags/update',
                component: () => import('../views/pages/tags/update'),
            },{
                path: '/brands',
                name: 'brands',
                component: () => import('../views/pages/brands/index'),
            },{
                path: '/brands/create',
                name: 'brands/create',
                component: () => import('../views/pages/brands/create'),
            },{
                path: '/brands/update',
                name: 'brands/update',
                component: () => import('../views/pages/brands/update'),
            },{
                path: '/category',
                name: 'category',
                component: () => import('../views/pages/category/index'),
            },{
                path: '/category/create',
                name: 'category/create',
                component: () => import('../views/pages/category/create'),
            },{
                path: '/category/update',
                name: 'category/update',
                component: () => import('../views/pages/category/update'),
            },{
                path: '/permission',
                name: 'permission',
                component: () => import('../views/pages/permission/index')
            },{
                path: '/permission/create',
                name: 'permission/create',
                component: () => import('../views/pages/permission/create')
            },{
                path: '/permission/update',
                name: 'permission/update',
                component: () => import('../views/pages/permission/update')
            }, {
                path: '/holiday',
                name: 'holiday',
                component: () => import('../views/pages/holiday/index')
            }, {
                path: '/holiday/create',
                name: 'holiday/create',
                component: () => import('../views/pages/holiday/create')
            },{
                path: '/holiday/update',
                name: 'holiday/update',
                component: () => import('../views/pages/holiday/update')
            },{
                path: '/invoice',
                name: 'invoice',
                component: () => import('../views/pages/invoice/index')
            },{
                path: '/invoice/create',
                name: 'invoice/create',
                component: () => import('../views/pages/invoice/create')
            },{
                path: '/invoice/update',
                name: 'invoice/update',
                component: () => import('../views/pages/invoice/update')
            },{
                path: '/delivery',
                name: 'delivery',
                component: () => import('../views/pages/delivery/index')
            }
        ]
    }
]
